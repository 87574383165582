import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";
class ItemGroupService{
    editGroupItem(formData,id){
        const url = `api/item/group/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formData,headers);
    }
    getFilteredItemGroupLists(param){
        const url = `api/item/groups/filter`
        const params = {
            'page-index':param.size,
            'offset':param.offset,
            'searched':param.searched,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    createNewItemGroup(formData){
        const url = `api/item/group/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    deleteGroupItem(id){
        const url = `api/item/group/${id}`;
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url,headers);
    }
}
export default new ItemGroupService();